    .share-icon {
        width: 20px;
        cursor: pointer;
        margin-left: 5px;
    }
    
    @media screen and (max-width: 600px) {
        .share-icon {
            width: 30px;
            cursor: pointer;
            margin-left: 5px;
        }
    }

    .barrato{
        text-decoration: line-through;
      }
    
    .container-fields {
        min-height: 100px;
        height: auto;
        font-family: 'Titillium Web', sans-serif !important;
        /* min-height: 200px; */
    }
    
    .container-fields * {
        font-family: 'Titillium Web', sans-serif;
    }
    
    .varieties-container {
        margin-bottom: 15px;
    }
    
    /* .varieties-container {} */
    
    .carousel-container {
        /* border: 1px solid;
        border-color: var(--primary-color) !important; */
        height: 220px;
        position: relative;
    }
    
    .carousel-container .carousel.carousel-slider {
        max-height: 218px;
    }
    
    
    .carousel .slide img {
        max-height: 218px;
        width: auto !important;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    
    .offertaBadge {
        position: absolute;
        top: 5px;
        left: 5px;
        z-index: 2;
        font-family: 'Titillium Web', sans-serif;
    }
    
    .offertaBadge p,
    .product-price span,
    .container-add-to-list h6,
    .container-add-to-list h6 span {
        font-family: 'Titillium Web', sans-serif;
    }

    .product-price span{
        color: #ff0000;
    }

    /*
.container-add-to-list {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.qty-add-to-list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.qty-add-to-list>svg {
    cursor: pointer;
}
*/
    
    .container-variety {
        margin-bottom: 15px;
    }
    
    #variety {
        background-color: #e3e3e4;
        font-family: 'Titillium Web', sans-serif;
    }
    
    .container-add-to-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .container-add-to-list-color {
        background-color: #e3e3e4;
    }
    
    .container-add-to-list>* {
        margin: 5px;
    }
    
    .container-add-to-list>svg {
        cursor: pointer;
    }
    
    .markers-container {
        display: flex;
        display: -webkit-flex;
        /* max-height: 90px; */
        max-height: 30px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -ms-flexbox;
    }
    
    /* .markers-container>.marker[type="plus"] {
         display: none !important;
    } */
    
    .markers-container div:hover {
        cursor: pointer;
    }
    
    .hlJSWm .roe-card-style .roe-card-body {
        padding: 15px 24px 15px;
    }
    
    @media screen and (max-width: 720px) {
        .container-add-to-list {
            position: initial
        }
        .container-fields {
            min-height: 0px;
        }
        .varieties-container {
            min-height: 0px;
        }
        .product-price {
            justify-content: center
        }

        .carousel-container {
            height: 180px;
        }
        
        .carousel-container .carousel.carousel-slider {
            max-height: 178px;
        }

        .carousel-container img {
            max-height: 178px !important;
        }
        
        .carousel .slide img {
            max-height: none;
        }

    }

    body {
        touch-action: pan-y;
      }